import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";

export default class extends Controller {
  static values = { slug: String };
  static targets = ["percent", "stage"];
  slugValue: string;
  percentTarget: HTMLSpanElement;
  stageTarget: HTMLSpanElement;
  interval: any;
  countSamePercent: number = 0;

  connect() {
    this.startRefreshing();
  }

  startRefreshing() {
    this.interval = setInterval(() => {
      this.load();
    }, 500);
  }

  async load() {
    const req = await fetch("/progress/" + this.slugValue);
    const { stage, progress } = await req.json();

    if (stage === "done") {
      clearInterval(this.interval);
      Turbo.clearCache();
      Turbo.visit("/" + this.slugValue, { action: "replace" });
    }

    this.percentTarget.innerText = progress;
    this.stageTarget.innerText = stage;
  }

  disconnect() {
    clearInterval(this.interval);
  }
}
