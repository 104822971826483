import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  uppy: any;

  async connect() {
    const uppyComponent = await import("../components/upload");

    const root = this.targets.find("root").id;
    this.uppy = uppyComponent.Uppy("#" + root);
  }

  disconnect() {
    this.uppy.close();
  }
}
