import { Controller } from "@hotwired/stimulus";

(self as any).MonacoEnvironment = {
  getWorkerUrl: function (moduleId, label) {
    if (label === "json") {
      return "./json.worker.js";
    }
    if (label === "css" || label === "scss" || label === "less") {
      return "./css.worker.js";
    }
    if (label === "html" || label === "handlebars" || label === "razor") {
      return "./html.worker.js";
    }
    if (label === "typescript" || label === "javascript") {
      return "./ts.worker.js";
    }
    return "./editor.worker.js";
  },
};

export default class extends Controller {
  static values = { lang: String, path: String };

  monacoLoader: Promise<any>;
  monaco: any;
  langValue: string;
  pathValue: string;

  async connect() {
    const req = await fetch(this.pathValue);
    const text = await req.text();

    this.monaco = await import("monaco-editor");
    const root = this.targets.find("root");

    const editor = this.monaco.editor.create(root, {
    });

    const model = this.monaco.editor.createModel(
      text,
      undefined, // language
      this.monaco.Uri.file(this.pathValue) // uri
    )
    
    editor.setModel(model)
  }
}
