import 'babel-polyfill' // lines of code which make you remember why you hate parcel

import { Application } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";

import Uploader from "./controllers/upload";
import Monaco from "./controllers/monaco";
import Progress from "./controllers/progress";

require("./style.postcss");

Turbo.start();

const application = Application.start();
application.register("upload", Uploader);
application.register("monaco", Monaco);
application.register("progress", Progress);
